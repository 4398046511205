import create from 'zustand';
// eslint-disable-next-line import/no-unresolved
import produce from 'immer';

const useStore = create((set) => ({
  isError: null,
  setIsError: (val) => set({ isError: val }),
  isInfoMessage: null,
  setIsInfoMessage: (val) => set({ isInfoMessage: val }),
  isMenuOpen: false,
  setIsMenuOpen: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
  isModalOpen: false,
  setIsModalOpen: () => set((state) => ({ isModalOpen: !state.isModalOpen })),
  tokenData: null,
  setTokenData: (val) => set({ tokenData: val }),
  userData: null,
  setUserData: (val) => set({ userData: val }),
  listOfUsers: null,
  setListOfUsers: (val) => set({ listOfUsers: val }),

  userListSorting: {
    field: 'sort_date',
    order: 'desc',
  },
  setUserListSorting: (val) => set({ userListSorting: val }),

  userListRoleFilter: '',
  setUserListRoleFilter: (val) => set({ userListRoleFilter: val }),

  addGroupToUser: (index, groupid) =>
    set(
      produce((draft) => {
        draft.listOfUsers[index].user_groups.push(groupid);
      })
    ),
  removeGroupFromUser: (index, groupid) =>
    set(
      produce((draft) => {
        const groupIndex = draft.listOfUsers[index].user_groups.indexOf(
          groupid
        );
        draft.listOfUsers[index].user_groups.splice(groupIndex, 1);
      })
    ),
  insertUpdatedUser: (newUserObj) =>
    set(
      produce((draft) => {
        const index = draft.listOfUsers.findIndex(
          (u) => u.userid === newUserObj.userid
        );
        draft.listOfUsers.splice(index, 1, newUserObj);
      })
    ),
  listOfGroups: null,
  setListOfGroups: (val) => set({ listOfGroups: val }),
  clearGlobalStates: () =>
    set({
      isMenuOpen: false,
      isModalOpen: false,
      tokenData: null,
      userData: null,
      listOfUsers: null,
      listOfGroups: null,
    }),
}));

export default useStore;
